import React from 'react'
import AdSense from 'react-adsense'

const AdAngel = () => {

    if (process.env.NODE_ENV !== 'production') return <div className='w-full h-full rounded bg-slate-50'></div>

    return <AdSense.Google
        client="ca-pub-8460495181619769"
        slot="3878170298"
        style={{ display: 'block' }}
        layoutKey="-73+ed+2i-1n-4w"
        format="fluid"
    />
}

export default AdAngel