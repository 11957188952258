import React, { useEffect, useState } from 'react';

import Layout from "../components/layout"
import Seo from "../components/seo"

import Game from "../components/game"
import AdBlock from '../components/adBlock';

import app from 'gatsby-plugin-firebase-v9.0'
import { getFirestore, getDoc, getDocs, doc, collection } from "firebase/firestore";
import AdAngel from '../components/adAngel';

const GamePage = ({ serverData }) => {

  const [ games, setGames ] = React.useState([]); 

  React.useEffect(() => {

    (async () => {

      const db = getFirestore(app);
      const querySnapshot = await getDocs(collection(db, "games"));

      let _games = [];

      querySnapshot.forEach(i => { 
        
        _games.push({ 
          ...i.data(), 
          id: i.id 
        }) 
      
      });

      setGames(_games);
      console.log(_games);

    })();

  }, [ ])

  return <Layout>
    <script type="text/javascript" src="https://unpkg.com/@ruffle-rs/ruffle"></script>
    {/* <script defer dangerouslySetInnerHTML={{ __html: ruffleHTML.replace(/\[SWF_URL\]/g, serverData.gameFile) }} /> */}
    <div className="flex flex-row gap-4 p-4">
        <section className="w-full">
            <div className='w-full'>
                <div className='w-full rounded-xl overflow-hidden p-0' id="gameContainer">
                    <embed src={serverData.gameFile} className="aspect-video" margin="0px" width="100%" height="100%"></embed>
                </div>
            </div>
            <h1 className="text-3xl font-bold mt-4">{serverData.name}</h1>
            <div className='p-4 bg-slate-100'>
            </div>
        </section>
        <section className="w-2/5 flex flex-col gap-2">
            <div>
                <AdAngel />
            </div>
            {
                games.map(game => <>
                    <Game {...game} renderStyle="horizontal" />
                </>)
            }
        </section>
    </div>
  </Layout>
}

export async function getServerData() {

    const id = "dUvobiEf7agb0YGb7f4s";

    const db = getFirestore(app);
    const querySnapshot = await getDoc(doc(db, "games", id));

    if (!querySnapshot.exists()) return {
        status: 404,
        headers: {},
        props: {}
    }

    return {
        props: querySnapshot.data()
    }

}

export const Head = ({ serverData }) => { 
    
    return <Seo title={serverData?.name || "Game Page"} /> 

}

export default GamePage
